@import "../../../../assets/scss/variables";

.service-benefits {

	padding-top: 4rem;

	& .title {
		font-weight: 700;
		text-align: left;
	}

	& .sub-title {
		font-weight: 700;
		padding-bottom: 2rem;
		text-align: left;
	}

	& .cards {
		gap: 2rem;

		& .benefits-card {
			display: flex;
			justify-content: end;
			align-items: center;
			font-size: 1.2rem;

			cursor: pointer;
			//padding: 2rem;
			color:$white;
			height: 36rem;
			transition:
				left 0.5s,
				width 0.5s;
			overflow: hidden;

			&.active {
				width: 100%;
				padding-left: 2rem;
				padding-right: 2rem;
				& .card-description {
					padding-bottom: 2rem;
				}
			}

			&.inactive {
				overflow: hidden;
				width: 12rem;
				padding-left: 0rem;
				padding-right: 0rem;
				& .card-title {
					display: none;
				}
				& .card-description {
					display: none;
				}
				& .icon-container {
					flex-direction: column;
					margin-bottom: 3rem;
				}
			}

			& .bg-gradient {
				border-radius: 2rem;
				background: linear-gradient(180deg, rgba(109, 126, 155, 0) 0%, #6D7E9B 78%) !important;
				box-shadow: 5px 5px 10px 0px #0000001A;
			}

			& .card-title {
				white-space: nowrap;
				width: 100%;
				text-align: left;
				font-size: $font-size-60;
				line-height: $lh-60;
				font-weight: 600;
			}

			& .card-description {
				white-space: nowrap;
				width: 100%;
				text-align: left;
				padding-top: 1rem;
				font-size: $font-size-28;
				line-height: $lh-28;
				font-weight: 400;
			}

			& .card-icon {
				margin-top: .5rem;
			}

			& .card-counter {
				text-align: left;
				font-size: $font-size-60;
				line-height: $lh-60;
				font-weight: 600;
			}

			& .card-image {
				border-radius: 2rem;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

		}

	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
		background-position-y: bottom;
	}

}
