.mb-row-column {
	flex-direction: row  !important;
	@media (max-width: $mobile-width) {
		flex-direction: column !important;
	}
}

.mb-column-row {
	flex-direction: column  !important;
	@media (max-width: $mobile-width) {
		flex-direction: row !important;
	}
}

.mb-row-column-reverse {
	flex-direction: row !important;
	@media (max-width: $mobile-width) {
		flex-direction: column-reverse !important;
	}
}
.mb-text-center {
	@media (max-width: $mobile-width) {
		text-align: center !important;
	}
}

.mb-hide {
	@media (max-width: $mobile-width) {
		display: none !important;
	}
}

.mb-only {
	display: none;
	@media (max-width: $mobile-width) {
		display: inherit;
	}
}

.circles-svg {
	@media (max-width: $mobile-width) {
		opacity: .6;
	}
}

.mb-justify-unset {
	@media (max-width: $mobile-width) {
		justify-content: unset !important;
	}
}
.mb-column-reverse {
	@media (max-width: $mobile-width) {
		flex-direction: column-reverse !important;
	}
}
.mb-align-items-center {
	@media (max-width: $mobile-width) {
		align-items: center !important;
	}
}
