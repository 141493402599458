@import "../../../../assets/scss/variables";

.service-applications {

	color:$black;
	user-select: none;

	& .icon {
		font-weight: 600;
	}

	& .pre-title {
		font-size:$font-size-28;
		line-height:$lh-28;
		font-weight: 600;
	}
	& .title {
		font-size:$font-size-60;
		line-height:$lh-60;
		font-weight: 600;
	}
	& .description {
		font-size:$font-size-28;
		line-height:$lh-28;
		font-weight: 400;
		padding: 2rem 10rem 2rem 0rem;
	}

	& .icon-container {
		color: $blue;
	}

	& .service-container {
		width: 50%;
		@media (max-width: $mobile-width) {
			width: 100%;
		}
	}
	& .swipe-container {
		background: $black-2;
		box-shadow: 5px 5px 10px 0px #0000001A;
		border-radius: 1rem;

		z-index: 2;
		min-width: 60%;
		max-width: 60%;
		min-height: 45rem;
		padding-left: 2rem;
		padding-right: 2rem;
		@media (max-width: $mobile-width) {
			min-width: 100%;
			max-width: 100%;
		}


		& .swipe-container-inside {
			//background: red;
			//height: 26rem;
			//margin-right: 2rem;
		}

		.swiper-wrapper {
			//padding-right: 4rem !important;
		}

		//& .image {
		//	border-radius: .5rem;
		//	box-shadow: 5px 5px 10px 0px #0000001A;
		//	background-repeat: no-repeat;
		//	background-size: cover;
		//	background-position: center;
		//}
		& .swiper-slide {
			//max-width: 20rem;
			min-width: 20rem;
			max-width: 20rem;
			height: 24rem;
			@media (max-width: $mobile-width) {
				height: 30rem;
			}
		}

		& .service-applications-card {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.2rem;

			border-radius: 5px;
			padding-left: 2rem;
			padding-right: 2rem;

			cursor: pointer;
			//padding: 2rem;
			color:$black;
			background-color: $white;

			width: 100%;
			height: 100%;
			overflow: hidden;

			//&:not(:first-child) {
			//	border-left: solid 4px $black;
			//	//margin-left: 4rem;
			//}
			//&:nth-child(3n+1) {
			//	border-left: none;
			//	margin-left: 0rem;
			//}

			& .card-title {
				user-select: none;
				text-align: center;
				padding-top: 1rem;
				font-weight: 600;
				color: $purple;
			}

			& .card-image {
				user-select: none;
				background-repeat: no-repeat;
				background-size: cover;
			}

			& .card-icon {
				user-select: none;
				color: $purple-3;
			}

			& .card-description {
				color: $purple;
				user-select: none;
				text-align: center;
				padding-top: 1rem;
				font-weight: 400;
			}

		}
	}


}
