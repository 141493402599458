@import "../../../../assets/scss/variables";

.marquee-logos {
    //background-color: #51d4f3;
    //color: #279eba;
    font-weight: bold;
    text-transform: uppercase;

	& .item {
		width:16rem;
		height:8rem;
		display: flex;
	}

	& .image {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
	}
}
