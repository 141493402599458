.select2-selection__control {
	background-color: transparent;
	min-width: 225px;

	font-size: 2.133vw;
	border: 1px solid #43597e;
	background-color: transparent !important;
}

.pe-5 {
	background-color: transparent !important;
}
