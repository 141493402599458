@import "../../../../assets/scss/variables";

.our-values {
	padding-bottom: 6rem;

	& .title {
		font-size: $font-size-96;
		font-weight: 700;
		padding-top: 4rem;
		padding-bottom: 2rem;
		text-align: center;
	}

	& .content {
	}

	& .cards {
		width: 100%;

		display: grid;
		grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
		gap: 2rem; /* Space between items */

		@media (max-width: $mobile-width) {
			display: flex;
			flex-direction: column;
		}

		& .our-values-card {
			padding-left: 2rem;
			padding-right: 2rem;

			&:not(:first-child) {
				border-left: solid 4px $black;
				@media (max-width: $mobile-width) {
					border-left: unset;
				}
			}
			&:nth-child(3n + 1) {
				border-left: none;
				margin-left: 0rem;
			}

			cursor: pointer;
			//padding: 2rem;
			color: $black;

			width: 21rem;
			height: 25rem;
			@media (max-width: $mobile-width) {
				height: 18rem;
				border-bottom: solid 4px $black;
			}
			overflow: hidden;
			//border: solid 1px black;

			& .card-title {
				padding-top: 1rem;
				font-size: $font-size-28;
				line-height: $lh-28;
				font-weight: 600;
				@media (max-width: $mobile-width) {
					padding-top: 0rem;
					padding-left: 1rem;
				}
			}

			& .card-image {
				opacity: 0.9;
				background-repeat: no-repeat;
				background-size: cover;
			}

			& .card-description {
				padding-top: 1rem;
				font-size: $font-size-28;
				line-height: $lh-28;
				font-weight: 400;
			}
		}
	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
		background-position-y: bottom;
	}
}
