@import "../../../../assets/scss/variables";

.service-functions {

	padding-top: 5rem;

	& .line-top {
		background-color: $black;
		height: .25rem;
		margin: 0rem 20rem 0rem 20rem;
	}

	& .line-bottom {
		background-color: $black;
		height: .25rem;
		margin: 3rem 20rem 0rem 20rem;

	}

	& .title {
		font-weight: 700;
		text-align: center;
	}

	& .sub-title {
		color:$blue;
		font-size:$font-size-28;
		font-weight: 700;
		padding-bottom: 2rem;
		text-align: center;
	}

	& .content {
	}

	& .cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 3rem;
		width: 100%;
		background-color: #f4f4f4;
		padding: 0rem 8rem 0rem 8rem;

		& .service-functions-card {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.2rem;

			border-radius: 5px;
			padding-left: 2rem;
			padding-right: 2rem;

			cursor: pointer;
			//padding: 2rem;
			color:$black;

			width: 20rem;
			height: 28rem;
			overflow: hidden;
			@media (max-width: $mobile-width) {
				min-width: 30rem;
			}

			//&:not(:first-child) {
			//	border-left: solid 4px $black;
			//	//margin-left: 4rem;
			//}
			//&:nth-child(3n+1) {
			//	border-left: none;
			//	margin-left: 0rem;
			//}

			&:nth-child(odd) {
				background-color: $purple-2;
				color: $black;
				& .card-title {
					color: $black;
				}
				& .card-description {
					color: $black;
				}
				& .card-icon {
					color: $black;
				}
			}

			&:nth-child(even) {
				background-color: $purple;
				color: $white;
				& .card-title {
					color: $white;
				}
				& .card-title {
					color: $white;
				}
				& .card-icon {
					color: $white;
				}
			}

			/* Specific placement for second-row items */
			&:nth-child(4) {
				grid-column: 1 / span 1;
			}
			&:nth-child(5) {
				grid-column: 3 / span 1;
			}

			& .card-title {
				text-align: center;
				padding-top: 1rem;
				font-weight: 600;
			}

			& .card-image {
				background-repeat: no-repeat;
				background-size: cover;
			}

			& .card-description {
				text-align: center;
				padding-top: 1rem;
				font-weight: 400;
			}

		}

	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
		background-position-y: bottom;
	}

}
