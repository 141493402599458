@import "variables";
@import "zoom";
@import "components/mobile-config";
@import "components/page-config";
@import "components/typography";
@import "form-skins/form-admin";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for modern browsers */
.no-scrollbar {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
}

.no-scrollbar::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Edge */
}
