@import "../../../../assets/scss/variables.scss";

.button {
	//width: 288px;
	//height: 70px;
	//top: 865.08px;
	//left: 160px;
	white-space: nowrap;
	gap: 0px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	line-height: 32.81px;
	text-align: left;
	//text-underline-position: from-font;
	border: 0;
	text-decoration-skip-ink: none;
	color:$white;
	cursor: pointer;
	text-decoration: none;
			transition: all .2s ease-in-out;
		& .icon {
			margin-top: -.3rem;
			transition: all .2s ease-in-out;
		}

	&[data-template=blue] {
		background: $blue;
		box-shadow: 5px 5px 10px 0px #0000001A;
		&:hover {
			color:$blue-4;
		}
	}

	&[data-template=light-blue] {
		background: $blue-4;
		box-shadow: 5px 5px 10px 0px #0000001A;
	}

	&[data-template=link] {
		background: transparent;
		color: $blue;
		&:hover {
			color:$purple-2;
			text-decoration: underline;
			text-decoration-color: $purple-2;
			& .icon {
				transform: scale(1.25);
			}
		}
	}

	&[data-template=link-underline] {
		border-radius: 0;
		background: transparent;
		color: $white;
		border-bottom: solid 1px $white;
		font-size: $font-size-18 !important;
		&:hover {
			color:$blue-4;
			& .icon {
				transform: scale(1.25);
			}
		}
	}

	&[data-template=link-underline-purple] {
		border-radius: 0;
		background: transparent;
		color: $purple;
		border-bottom: solid 1px $purple;
		font-size: $font-size-18 !important;
		&:hover {
			& .icon {
				transform: scale(1.25);
			}
		}
	}

	&[data-size=big] {
		font-size: $font-size-28;
		padding:1.1rem 8rem 1.1rem 8rem;
		@media (max-width: $mobile-width) {
			padding:1.1rem 4rem 1.1rem 4rem;
		}
	}

	&[data-size=medium] {
		font-size: $font-size-20;
		//border: solid 1px white;
		padding:.5rem 2.5rem .5rem 2.5rem;
	}



}
