@import "../../../../assets/scss/variables";

.our-mission-vision {

	//padding-bottom: 6rem;

	& .card-container {

		gap: 6rem;

		& .card-box-mission {
			background: var(--azul-1, #294065);
			border-radius: .5rem;
			padding: 5rem 4rem;
			& .description {
				//padding-right: 10rem;
				padding-left: 8rem;
				@media (max-width: $mobile-width) {
					padding-left: 4rem;
				}
			}
		}

		& .card-box-vision {
			background: var(--roxo-3, #656B8F);
			border-radius: .5rem;
			padding: 5rem 4rem;
			& .description {
				//padding-right: 10rem;
				padding-right: 8rem;
				@media (max-width: $mobile-width) {
					padding-right: 4rem;
				}
			}
			& .title {
				//padding-right: 10rem;
				text-align: right;
			}
		}

		& .title {
			font-size: $font-size-60;
			font-weight: 600;
			color: $white;
			line-height: $font-size-60;
			text-transform: uppercase;
		}
		& .description {
			font-size: $font-size-28;
			font-weight: 400;
			color: $white;
			line-height: $font-size-28;
		}
	}



	//& .background-image {
	//	background-repeat: no-repeat;
	//	background-size: cover;
	//	background-position-x: center;
	//	background-position-y: bottom;
	//}

}
