@import "../../../../assets/scss/variables";

.client-description {

	color:$black;

	& .client-list {
		background: $blue;
		border-radius: .5rem;
		overflow-x: auto;
		padding: .25rem 1rem;
		gap: 3rem;
		& .item {
			white-space: nowrap;
			padding: .5rem 1rem;
			border-radius: .5rem;
			user-select: none;
			cursor: pointer;
			color: $white;
			font-size: $font-size-18;
			line-height: $lh-18;

			&:hover {
				background-color: $blue-4;
			}
		}
	}

	& .client-description-container {
		padding-top: 5rem;
	}

	& .client-solution-container {
		padding-top: 5rem;
	}

	& .title {
		font-weight: 700;
	}

	& .title-challenge {
		font-size: $font-size-60;
		line-height: $lh-60;
		font-weight: 600;
	}

	& .title-solution {
		font-size: $font-size-60;
		line-height: $lh-60;
		font-weight: 600;
	}

	& .description {
		font-size: $font-size-28;
		line-height: $lh-28;
		font-weight: 400;
		padding: 2rem 12rem 2rem 0rem;
	}

	& .image-container {
		background: #D9D9D9;
		z-index: 2;
		max-width: 30rem;
		min-width: 30rem;
		min-height: 20rem;
		max-height: 20rem;
		& .image {
			border-radius: .5rem;
			box-shadow: 5px 5px 10px 0px #0000001A;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}

	& .solution-items {
		min-width: 50%;
		gap:1.5rem;
		& .item {
				font-weight: 600;
			& .bullet {
				font-size: $font-size-28;
				font-weight: 600;
				min-width:3rem;
				height:3rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border: solid .2rem $black;
				border-radius: 50%;
				aspect-ratio: 1 / 1;
			}

			& .item-description {
				font-size: $font-size-28;
				line-height: $lh-28;
				font-weight: 600;
				padding-left: 1rem;
			}
		}
	}

	& .results-container {

		padding-top: 3rem;

		& .results-image-container {
			background: #D9D9D9;
			z-index: 2;
			width: 100%;
			height: 25rem;
			& .results-label {
				color:$white;
				font-size: $font-size-96;
				line-height: $lh-96;
				font-weight: 700;
			}

			& .results-image {
				border-radius: .5rem;
				box-shadow: 5px 5px 10px 0px #0000001A;
				background-repeat: no-repeat;
				background-size: cover;
				background-position-x: center;
				background-position-y: 0%;
			}
		}


		& .results-description {
			padding-top: 3rem;
			text-align: center;
			font-size: $font-size-28;
			line-height: $lh-28;
			font-weight: 400;
		}

		& .results-actions {
			padding-top: 4rem;
		}

	}

}
