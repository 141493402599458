@import "../../../../assets/scss/variables";

.tab-texts {

	.item {
		//border: solid 1px black;
		color:$black;
		cursor: pointer;
		&[data-state="active"] {
			color:$blue;
		}
	}

	.marker {
		transition:
			left 0.5s,
			width 0.5s;
		position: absolute;
		height: 1px;
		&.marker-container {
			background-color: $black;
		}
		&.marker-current {
			background-color: $blue;
			z-index: 1;
		}
	}
}
