@import "../../../../assets/scss/variables";

.hero {

	& .pre-title {
		font-size: 1rem;
	}

	& .title {
		font-family: $font-tt;
		//margin-right: 16rem;
		font-weight: 700;
	}
	& .description {
		//margin-right: 16rem;
		padding: 2rem 0rem 2rem 0rem;
	}
	& .actions {
		display: flex;
		flex-direction: row;
	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
	}

	& .bg-gradient-1 {
		background: linear-gradient(180deg, #F6F6F6 -6.36%, rgba(177, 184, 219, 0) 35.54%);
	}

	& .bg-gradient-2 {
		background: linear-gradient(180deg, rgba(177, 184, 219, 0) -7.44%, #F4F4F9 92.48%);
	}
}
