@import "../../../../assets/scss/variables";

.content-history {
	margin-top: -8rem;
	@media (max-width: $mobile-width) {
		margin-top: -4rem;
	}
}
.history {

	color:$black;

	& .icon {
		font-weight: 600;
	}
	& .title {
		font-weight: 700;
	}
	& .description {
		//margin-right: 8rem;
		padding: 2rem 12rem 2rem 0rem;
		@media (max-width: $mobile-width) {
			padding: 2rem 2rem 2rem 0rem;
		}
	}
}
