@import "../../../../assets/scss/variables";

.products-more-info {

	color:$black;
	padding: 0rem 10rem;
	margin-top: 3rem;

	& .description-container {
		padding-top: 5rem;
	}

	& .title {
		font-size: $font-size-60;
		line-height: $lh-60;
		font-weight: 700;
	}

	& .description {
		font-weight: 400;
		padding: 2rem 12rem 2rem 0rem;
		@media (max-width: $mobile-width) {
			padding: 2rem 2rem 2rem 0rem;
		}
	}

	& .image-container {
		background: #D9D9D9;
		z-index: 2;
		max-width: 30rem;
		min-width: 30rem;
		@media (max-width: $mobile-width) {
			height: 30rem;

		}
		border-radius: 1rem;
		overflow: hidden;
		& .image {
			border-radius: 1rem;
			box-shadow: 5px 5px 10px 0px #0000001A;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}

	& .results-container {

		padding-top: 3rem;

		& .results-description {
			padding-top: 3rem;
			text-align: center;
			font-size: $font-size-28;
			line-height: $lh-28;
			font-weight: 400;
		}

		& .results-actions {
			padding-top: 4rem;
		}

	}

}
