@import "../../../../assets/scss/variables";

.our-services {
	padding-bottom: 6rem;

	& .title {
		font-family: $font-tt;
		margin-right: 8rem;
		font-weight: 600;
		padding-top: 6rem;
		padding-bottom: 2rem;
	}

	& .content {
	}

	& .cards {
		gap: 2rem;

		& .card {
			cursor: pointer;
			padding: 2rem;
			color: $white;
			box-shadow: 5px 5px 10px 0px #0000001a;
			background: #46464680;

			width: 19rem;
			height: 25rem;
			@media (max-width: $mobile-width) {
				width: 100%;
			}

			border-radius: 10px;
			overflow: hidden;
			border: solid 1px black;

			& .card-title {
				font-family: $font-tt;
			}

			& .card-image {
				opacity: 0.9;
				background-repeat: no-repeat;
				background-size: cover;
			}

			& .card-description {
				padding-left: 0.2rem;
			}

			& .card-icon-badge {
				transition: all 0.3s ease-out;
				background-color: $white;
				color: $white;
				width: 3.5rem;
				height: 3.5rem;
				right: 0;
				bottom: 0;
				border-top-left-radius: 75%;
				border-bottom-left-radius: 10%;
				border-top-right-radius: 10%;
				position: relative;
				box-shadow: 0px 0px 2px 1px $white;
			}

			& .card-icon-container {
				transition: all 0.3s ease-in-out;
				position: absolute;
				right: 0.25rem;
				bottom: 0.25rem;
				background-color: $black;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				padding: 0.75rem;
				aspect-ratio: 1 / 1;
			}

			&:hover {
				& .card-icon-badge {
					width: 4rem;
					height: 4rem;
					border-bottom-left-radius: 40%;
					border-top-right-radius: 40%;
					box-shadow: 0px 0px 2px 1px $white;
				}
				& .card-icon-container {
					right: 0.5rem;
					bottom: 0.5rem;
				}
			}
		}
	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
		background-position-y: bottom;
	}
}

//* EMBLA CAROUSEL *//

.embla {
	margin: auto;
	--slide-height: 19rem;
	--slide-spacing: 1rem;
	--slide-size: 70%;
}
.embla__viewport {
	overflow: hidden;
}
.embla__container {
	display: flex;
	touch-action: pan-y pinch-zoom;
	margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
	transform: translate3d(0, 0, 0);
	flex: 0 0 var(--slide-size);
	min-width: 0;
	padding-left: var(--slide-spacing);
}
.embla__slide__number {
	box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
	border-radius: 1.8rem;
	font-size: 4rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--slide-height);
	user-select: none;
}
.embla__controls {
}

.embla__dots {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
	-webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent;
	touch-action: manipulation;
	display: inline-flex;
	text-decoration: none;
	cursor: pointer;
	border: 0;
	padding: 0;
	margin: 0;
	width: 4vw;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
.embla__dot:after {
	box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
	width: 2.133vw;
	height: 2.133vw;
	border-radius: 50%;
	display: flex;
	align-items: center;
	background-color: #20324e;
	content: "";
}
.embla__dot--selected:after {
	box-shadow: inset 0 0 0 0.2rem var(--text-body);
	border: solid 2px #20324e;
	background-color: transparent;
}

//* EMBLA CAROUSEL *//
