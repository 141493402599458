@import "../../../../assets/scss/variables";

.our-team {


	& .pre-title {
		font-size:$font-size-18;
		line-height: $lh-18;
		font-weight: 400;
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-align: center;
		color: $blue-3;
	}

	& .title {
		font-size:$font-size-96;
		line-height: $lh-96;
		font-weight: 700;
		padding-bottom: 5rem;
		text-align: center;
	}

	& .title-together {
		font-size:$font-size-60;
		line-height: $lh-60;
		font-weight: 600;
		padding-top: 5rem;
		padding-bottom: 5rem;
		text-align: center;

		& .heart {
			font-size: 3rem;
			line-height: 3rem;
		}
	}

	& .content {
	}

	& .cards {
		width: 100%;

		display: grid;
		grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
		gap: 2rem; /* Space between items */

		@media (max-width: $mobile-width) {
			display: flex;
			flex-direction: column;
		}

		& .our-team-card {
			border-radius: 1rem;
			//padding-left: 2rem;
			//padding-right: 2rem;

			//cursor: pointer;
			//padding: 2rem;
			color:$black;

			height: 35rem;
			overflow: hidden;
			//border: solid 1px black;

			& .card-title {
				font-weight: 700;
			}

			& .card-image {
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center top;
			}

			& .card-description {
				padding-top: .5rem;
				font-weight: 400;
			}

			& .card-info-box {
				//min-height: 11rem;
				border-radius: .5rem;
				padding: 2rem 2rem 2rem 2rem;
				margin-right: 6rem;
				background: var(--azul-2, #25395A);
				color: $white;
			}

		}

	}

	& .background-image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
		background-position-y: bottom;
	}

}
