@import "../../../../assets/scss/variables";

.cases {

	border-radius: 25px;
	background: $black;
	color:$white;
	box-shadow: 5px 5px 10px 0px #0000001A;
	padding-left: 5rem;
	padding-right: 4rem;
	padding-top: 2rem;
	padding-bottom: 0rem;

	& .main-title {
		font-family: $font-tt;
		font-size: $font-size-60;
		font-weight: 600;
	}

	& .menu {
		gap: 4rem;
		border-bottom: solid .25rem $white;
		overflow-x: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		white-space: nowrap;
		& .item {
			cursor: pointer;
			font-weight: 400;
			&.active {
				font-weight: 600;
				color: $blue-4;
			}
		}
	}

	& .info-container {
		padding-left: 3rem;
		padding-top: 7rem;
	}

	& .title {
		font-weight: 600;
		font-family: $font-tt;
	}
	& .description {
		//margin-right: 8rem;
		padding: 2rem 2rem 2rem 0rem;
	}
	& .actions {
		display: flex;
		flex-direction: row;
		padding-bottom: 6rem;
	}

	& .middle-bar {
		background: $blue-3;
		width: 18rem;
	}

	& .image {
		min-width: 40%;
		flex-basis: 40%;
		min-height: 56rem;
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: right;
		background-position-y: center;
	}

}
