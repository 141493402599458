@import "../../../../assets/scss/variables";

.dompa-media {

	& .main-title {
		font-family: $font-tt;
		font-weight: 600;
		text-transform: uppercase;
		font-size: $font-size-60;
		font-weight: 600;
		color:$blue;
	}

	& .logos {
	}

	& .mid-line-container {
		padding: 0rem 1rem 0rem 1rem;
		& .mid-line {
			background-color: $blue;
			height:.5rem;
			width: 100%;
		}
	}

	& .news-container {
		gap: 2rem;
		padding: 0;
		margin: 0;
	}

	& .main-news {
		min-width: 40%;
		flex-basis: 50%;
		cursor: pointer;
		padding: 0rem;
		color:$white;

		& .main-news-container {
			padding: 0rem 2rem 2rem 2rem;
			min-height: 22rem;
		}

		& .image {
			background-repeat: no-repeat;
			background-size: cover;
			background-position-x: center;
			border-radius: 10px;
		}
		& .title {
			//font-size: $font-size-28;
			color: $white;
			font-weight: 600;
		}
		& .description {
			//font-size: $font-size-18;
			color: $white;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 3; /* Number of lines */
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			//font-weight: 600;
		}

		& .main-gradient {
			background: linear-gradient(180deg, rgba(46, 71, 112, 0) 35.69%, #2E4770 88.89%);
			border-radius: 10px;
		}
	}

	& .sub-news {
		gap: 2rem;

		//flex-basis: 50%;
		& .image-container {
			width: 13rem;
			height: 10rem;
		}
		& .image {
			background-repeat: no-repeat;
			background-size: cover;
			background-position-x: center;
			border-radius: 10px;
		}
		& .title {
			//font-size: $font-size-18;
			color: $black;
			font-weight: 400;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2; /* Number of lines */
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		& .description {
			//font-size: $font-size-12;
			color: $black;
			font-weight: 400;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 3; /* Number of lines */
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		& .sub-gradient {
			background: linear-gradient(180deg, rgba(46, 71, 112, 0) 35.69%, #2E4770 88.89%);
			border-radius: 10px;
		}
	}

}
