@import "../../../../assets/scss/variables";

.who-we-are {

	border-radius: 25px;
	background: $black;
	color:$white;
	box-shadow: 5px 5px 10px 0px #0000001A;

	& .container {
		padding-left: 4rem;
		padding-right: 4rem;
		padding-top: 6rem;
		padding-bottom: 6rem;
	}


	& .title {
		font-family: $font-tt;
		font-weight: 600;
	}
	& .description {
		//margin-right: 8rem;
		padding: 2rem 2rem 2rem 0rem;
	}
	& .actions {
		display: flex;
		flex-direction: row;
	}

	& .image-container {
		width: 110rem;
	}

	& .image-box {
		//border: solid 1px yellow;
		box-shadow: 5px 5px 10px 0px #0000001A;
		border-radius: 10px;

		width:22rem;
		height:26rem;
		right:6rem;
		top:1rem;
	}

	& .image-circle {
		width:22rem;
		height:22rem;
		right:-1rem;
		top:-4rem;
	}

	& .image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: -140px;
		//background-position-y: center;
	}

}
