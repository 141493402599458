@import "../../../../assets/scss/variables.scss";

$background : $white;
$border-color:#464646;
$white-2 : darken($white, 4);

//$top-menu-background-opacity : 0.9;

.top-menu-bar {
	user-select: none;
	color:$black;
	padding: 1rem 1rem 1rem 1rem  ;
	background: $background;
	position: relative;
	z-index: 11;
	min-height: 3rem;
	height:100%;
	@media (max-width: $mobile-width) {
	}

	& .logo-div {
		cursor:pointer;
		width:auto;
		height:19px;
	}

	& .menu-container {
		padding-left: 4rem;
		//position: absolute;
		z-index: 11;

		& .top-menu-item {
			padding: 0rem 1rem;
			font-family: $font-roboto;
			font-weight: 400;
			cursor: pointer;
			//border: solid 1px $success;
			border-radius: .25rem;

			&:hover {
				font-weight: 600;
				//background: $purple-3;
				//color: $white;
				//background: $white-2;
			}
		}


	}

	& .menu-avatar-container {
		cursor:pointer;
		color: $black;
	}

	& .top-menu-container {
		will-change: transform, opacity;
		gap: 5rem;
		transition:
			transform 318ms ease,
			opacity 318ms ease-in
		;
		//pointer-events: none;
		//border:solid 1px white;
		position: absolute;
		padding: 1rem 1rem 3rem 13.25rem;
		top:2rem;
		left:0;
		right:0;
		background: $background;
	}

	& .top-menu-container.open {
		transform: translateY(0rem) ;
		//pointer-events: unset;
		opacity: 1;
		z-index: 10;
	}
	& .top-menu-container.closed {
		//transition-delay: .5s;
		transform: translateY(-100%);
		opacity: 0;
		z-index: -1;
	}

	& .sub-menu-title {
		font-family: $font-roboto;
		color:$black;
		font-weight: 400;
		padding: .25rem .5rem;
		opacity: .75;
	}

	& .sub-menu-item-final {
		font-family: $font-roboto;
		color:$black;
		cursor:pointer;
		font-weight: 400;
		border-radius: .25rem;
		padding: .25rem .5rem;
		margin: 0rem 1rem;
		&:hover {
			font-weight: 600;
			//background: $purple-3;
			//color: $white;
		}
	}

	& .sub-menu-container {
	}

	& .sub-menu-container:first-child {

		& .sub-menu-item-final {
			//font-weight: 500;
			//font-size:1.1rem;
		}
		//border:solid 1px red;
	}

	& .sub-menu-items {
		display: grid;
		grid-template-columns: repeat(2, 1fr); /* 3 equal columns */
		//gap: 2rem; /* Space between items */

	}


}

.mobile-menu {

	& .MuiPaper-root {
		//border: solid 1px red;
		background: $black;
		color:$white;
		width: 75%;
	}

	& .logo-container {
		width: 100%;
		padding: 2rem 2rem 1rem 2rem;

		& .logo-div {
			cursor:pointer;
			width:100px;
			height:24px;
		}
	}

	& .menu-container {
		gap: 1rem;
		width: 100%;
		padding: 1rem 1rem;
		& .top-menu-item {
			padding: 0rem 1rem;
			font-family: $font-roboto;
			font-weight: 500;
			cursor: pointer;
			//border: solid 1px $success;
			border-radius: .25rem;

			&:hover {
				background: $purple-3;
				color: $white;
				//background: $white-2;
			}
		}

	}

}

