$show-zoom-debug: false;

/* Full HD reference resolution 1920x1080 */
$base-zoom: 100%;

$full-hd-zoom: $base-zoom * 1;

/* 4K Ultra HD (3840x2160) */
$ultra-hd-zoom: $base-zoom * 2;

/* WQHD (2560x1440) */
$wq-hd-zoom: $base-zoom * 1.3333;

/* HD+ (1600x900) */
$hd-plus-zoom: $base-zoom * 0.8333;

/* HD (1366x768) */
$hd-zoom: $base-zoom * 0.7115;

/* XGA+ (1280x800) */
$xga-plus-zoom: $base-zoom * 0.6667;

/* SXGA (1280x1024) */
$sxga-zoom: $base-zoom * 0.6667;

/* Lower resolutions (1024x768 and below) */
$lower-res-zoom: $base-zoom * 0.5333;

/* Full HD reference resolution 1920x1080 */
#root {
	zoom: $base-zoom !important;
	@if $show-zoom-debug {
		&:after {
			content: "Full HD";
			position: fixed;
			font-size: 2rem;
			top: 0;
			left: 0;
			z-index: 99999999;
		}
	}
}

/* 4K Ultra HD (3840x2160) */
@media only screen and (min-width: 3840px) {
	#root {
		//zoom: 200% !important;
		zoom: $ultra-hd-zoom !important;
		@if $show-zoom-debug {
			&:after {
				content: "4K Ultra HD";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

/* WQHD (2560x1440) */
@media only screen and (min-width: 2560px) and (max-width: 3839px) {
	#root {
		//zoom: 133.33% !important; /* 2560 / 1920 = 1.3333 */
		zoom: $wq-hd-zoom !important; /* 2560 / 1920 = 1.3333 */
		@if $show-zoom-debug {
			&:after {
				content: "4K Ultra HD";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

/* HD+ (1600x900) */
@media only screen and (min-width: 1600px) and (max-width: 1919px) {
	#root {
		//zoom: 83.33% !important; /* 1600 / 1920 = 0.8333 */
		zoom: $hd-plus-zoom !important; /* 1600 / 1920 = 0.8333 */
		@if $show-zoom-debug {
			&:after {
				content: "HD+";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

/* HD (1366x768) */
@media only screen and (min-width: 1366px) and (max-width: 1599px) {
	#root {
		//zoom: 71.15% !important; /* 1366 / 1920 = 0.7115 */
		zoom: $hd-zoom !important; /* 1366 / 1920 = 0.7115 */
		@if $show-zoom-debug {
			&:after {
				content: "HD";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

/* XGA+ (1280x800) */
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
	#root {
		//zoom: 66.67% !important; /* 1280 / 1920 = 0.6667 */
		zoom: $xga-plus-zoom !important; /* 1280 / 1920 = 0.6667 */
		@if $show-zoom-debug {
			&:after {
				content: "XGA+";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

/* SXGA (1280x1024) */
@media only screen and (min-width: 1280px) and (max-width: 1365px) and (min-height: 1024px) {
	#root {
		//zoom: 66.67% !important; /* 1280 / 1920 = 0.6667 */
		zoom: $sxga-zoom !important; /* 1280 / 1920 = 0.6667 */
		@if $show-zoom-debug {
			&:after {
				content: "SXGA";
				position: fixed;
				font-size: 2rem;
				top: 0;
				left: 0;
				z-index: 99999999;
			}
		}
	}
}

// /* Lower resolutions (1024x768 and below) */
// //@media only screen and (max-width: 1279px) {
// @media only screen and (max-width: $mobile-width) {
// 	#root {
// 		//zoom: 53.33% !important; /* 1024 / 1920 = 0.5333 */
// 		zoom: $lower-res-zoom !important; /* 1024 / 1920 = 0.5333 */
// 		@if $show-zoom-debug {
// 			&:after {
// 				content : "MOBILE";
// 				position: fixed;
// 				font-size: 2rem;
// 				top: 0;
// 				left: 0;
// 				z-index: 99999999;
// 			}
// 		}
// 	}
// }
