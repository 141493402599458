@import "../../../../assets/scss/variables";

.talk-to-us {

	padding: 0rem 7rem !important;
	@media (max-width: $mobile-width) {
	padding: 0rem 0rem !important;
	}

	& .title {
		font-size: $font-size-96;
		font-weight: 700;
		color:$blue;
	}

	.submit-container {
		padding-right: 3rem;
	}

	& .tabs {

		& .item {
			cursor: pointer;
			font-weight: 400;
			font-size: $font-size-28;
			color: $blue-4;

			&[data-state="active"] {
				font-weight: 500;
				color: $blue;
			}

		}

		.marker {
			height: .2rem;
			border-radius: 1rem;
			opacity: .2;
			&.marker-container {
				background-color: $black;
			}
			&.marker-current {
				opacity: 1;
				border-radius: .5rem;
				height: .4rem;
				margin-top: -.1rem;
				background-color: $blue-4;
				z-index: 1;
			}
		}
	}

	& .tab-container {

		//border: dashed 1px $black;
		border-top: none;
		padding: 0rem 2rem;

		min-height: 20rem;

		&[data-state=active] {
			display: flex;
		}

		&[data-state=inactive] {
			display: none !important;
		}
	}
}
