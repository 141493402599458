
//* svg-image-color needs to be an string without '#', it goes inside backgroundImage>url()>svg string

$form-text-color : $black;
$form-label-color : $black;
$form-svg-image-color : $black;

$form-input-background : #fff;
$form-input-border-color : $blue;
$form-input-border-radius : .25rem;
$box-border-radius : $form-input-border-radius;

$input-font-family : Roboto;
$form-input-box-shadow : none;

$primary : $blue;
$info : $blue;

$input-label-font-family : Roboto;

$font-family-europa : Roboto;

:root {
	--blue : #{$blue};
	--blue-3 : #{$blue-3};
	--blue-4 : #{$blue-4};
	--black : #{$black};
	--black-2 : #{$black-2};
	--white : #{$white};
	--white-2 : #{$white};
	--input-font-family : #{$input-font-family};
	--input-label-font-family : #{$input-label-font-family};
	--form-input-border-radius : #{$form-input-border-radius};
	--form-input-border-color : #{$form-input-border-color};
	--form-input-background : #{$form-input-background};
	--form-input-box-shadow : #{$form-input-box-shadow};
	--box-border-radius : #{$box-border-radius};
}

$form-themes: (
	admin: (
		input-background: $form-input-background,
		input-border-color: $form-input-border-color,
		input-border-radius: $form-input-border-radius,
		input-padding: 1.25rem 0 0 1rem,
		label-padding: 1.25rem 1rem,
		//background-image-valid: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23#{$form-svg-image-color}%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important,
		background-image-valid: none !important,
	),
	//"admin.read-only": (
	//	input-background: $form-input-background,
	//	input-border-color: transparent,
	//	input-border-radius: $form-input-border-radius,
	//	input-padding: 1.25rem 0 0 .75rem,
	//	label-padding: 1rem 0.75rem,
	//	//background-image-valid: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23#{$form-svg-image-color}%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e") !important,
	//	background-image-valid: none !important,
	//),
	//admin-round: (
	//	input-background: $black-2,
	//	input-border-color: transparent,
	//	input-border-radius: 1.5rem,
	//	input-padding: 1.2rem .25rem .25rem 1.5rem,
	//	label-padding: 1rem 1.5rem,
	//	background-image-valid: none !important,
	//)
);

/*
map-get($theme, label-padding)
*/

@each $state, $theme in $form-themes {

	.form-#{$state} {
		//color:white !important;

		& .form-floating > label {
			padding: map-get($theme, label-padding);
			font-size: $font-size-18;
		}
		.form-field-row {}
		//.form-field-col:first-child, :not(.form-field-col) + .form-field-col {
		//	border: solid 1px yellow;
		//	padding-left: 0px !important;
		//}
		//.form-field-col:last-child, :not(.form-field-col) + .form-field-col {
		//	border: solid 1px blue;
		//	padding-right: 0px !important;
		//}
		.form-field-col {
			//width: 100%;
		}
		.form-field-group {

		}

		.form-field-type-textarea {
			padding-top: 0rem !important;
		}
		TEXTAREA {
			padding-top:1.75rem !important;
			padding-bottom:0 !important;
			padding-right:1rem !important;
		}

		.check-group-container {
			border-bottom: solid 1px map-get($theme, input-border-color);
			padding: .5rem;
		}
		.custom-switch.form-field-input:focus {
			border: 0 !important;
			outline: 0 !important;
		}
		.custom-switch.form-field-input {
			padding-bottom: 1.25rem !important;
			border: 0 !important;
		}


		.input-file-upload {
			padding-left: 0 !important;
			min-height: 3.65rem !important;
		}

		.input-file-color {
			padding-left: 0 !important;
			min-height: 3.61rem !important;
		}

		.input-file-upload:hover .image-container {
			//transform:scale(10) !important;

		}


		.form-field-input:not([type="checkbox"]) {
			&::placeholder {color:$form-text-color; opacity: 1;}
			&:-ms-input-placeholder{color:$form-text-color}
			&::-ms-input-placeholder{color:$form-text-color}

			border-radius: map-get($theme, input-border-radius) !important;
			background-color: map-get($theme, input-background) !important;
			font-family: $input-font-family;
			font-weight: 300;
			//border-radius: 0px;
			//background-color: red;
			border: solid 1px map-get($theme, input-border-color);
			font-size: $font-size-18;
			//border-bottom-color: map-get($theme, input-border-color);
			color: $form-text-color;
			min-height:4rem;
			padding: map-get($theme, input-padding);

		}

		&.inline-form .custom-switch  {
			padding: 0 !important;;
			border-bottom: 0 !important;
		}

		.form-input-title {
			padding: 0rem 0 0 .75rem;
		}

		[type="checkbox"] {
			padding:0rem !important;
		}

		.form-field-type-check-group {
			padding: 0rem 0 0 .25rem;
		}

		.p-dialog-footer {
			z-index: 2;
		}
		.input-file-upload {
			margin-top:0px;
			//border:solid 10px red !important;
		}

		.form-control:focus, .selection2-selection__control--is-focused
		{
			box-shadow: $form-input-box-shadow !important;
		}

		.select2-selection__dropdown-indicator SVG {
			width: 1.25rem;
			height: 1.25rem;
		}

		.form-control.is-invalid:focus,
		.form-control.is-valid:focus,
		.was-validated .form-control:valid:focus {
			//box-shadow: 0 0 0 0.05rem rgba($white,1);
			box-shadow: 0 0 0 0.25rem rgba($white, .20);
			border-color:map-get($theme, input-border-color);
			//border-color:transparent;
		}
		.form-field-type-phone INPUT {
			width: 100%;
			//border:0 !important;
			outline: unset;
		}

		.form-field-type-phone INPUT:focus {
			//border-radius: 0 !important;
			box-shadow: $form-input-box-shadow !important;
		}

		//.select2-selection__control {background-color: red !important;};

		.form-control.is-invalid, .was-validated .form-control:invalid,
		.form-field-invalid .select2-selection__control {
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23#{$form-svg-image-color}%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23#{$form-svg-image-color}%27 stroke=%27none%27/%3e%3c/svg%3e");
			background-size: 1.25rem;
		}

		.form-control.is-valid, .was-validated .form-control:valid {
			background-image: map-get($theme, background-image-valid)
		}

		.form-field-invalid .select2-selection__indicator {
			padding-right: 1.5rem;
		}

		.form-field-type-password.form-field-touched .password-field-icon
		{
			padding-right: 1.75rem;
		}

		.form-field-disabled {
			pointer-events: none;
			filter:grayscale(.4);
			opacity:.3;
		}

		.form-field-disabled-visible,
		.form-field-disabled-visible LABEL {
			pointer-events: none;
			//filter:grayscale(.4);
			opacity:1;
			color:$purple !important;
		}

		.password-field-icon {
			float: right;
			margin-right: 6px;
			//margin-left: -25px;
			margin-top: -2.25rem;
			position: relative;
			z-index: 2;
		}

		.password-field-icon SVG {
			width: 1.25rem;
			height: 1.25rem;
			color:$white;
		}




		.form-field-invalid .select2-selection__control {
			transition: none;
			background-position: right calc(0.375em + 0.1875rem) center;
			//background-origin: content-box;
			padding-right: .65rem;
			background-repeat: no-repeat;
		}
		.form-control.is-valid, .was-validated .form-control:valid {
			background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23ffffff%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
		}
		.form-control.is-valid, .was-validated .form-control:valid {
			border-color: map-get($theme, input-border-color);
			//border-bottom-color: map-get($theme, input-border-color);
		}

		.form-field-input:focus {
			border: solid 1px map-get($theme, input-border-color) !important;
			//border-bottom-color: map-get($theme, input-border-color) !important;
		}

		.select2-selection__single-value, .select2-selection__indicator {
			color:$form-text-color !important;
		}

		.select2-selection__indicator:hover {
			color:$purple !important;
		}

		//.select2-selection__placeholder {
		//	padding-left:.5rem !important;
		//}


		.custom-switch {
			//height: 32px !important;
				//margin: 0px 10px 0px 10px !important;
				cursor: pointer;
				outline: 0 !important;
				//background: red !important;
			& .label {
				color: $white;
				&.checked {
					color:$primary;
					font-weight: 600;
				}
			}
			& .arrow {
				transition: transform .3s linear;
				transform: rotateY(0deg);
				&.checked {
					transform:rotateY(180deg);
				}

			}

			&.cs-on .react-switch-bg {
				pointer-events: none;
				background: darken($primary, 20) !important;
			}
			&.cs-off .react-switch-bg {
				pointer-events: none;
				background: $info !important;
			}
			& .react-switch-handle {
				pointer-events: none;
				border: solid 1px $primary !important;
				box-shadow: 0 0 0 8px rgba($primary, .1) !important;
				background: $primary !important;

				//margin-top: -5px !important;
				//height: 16px !important;
				//width: 16px !important;
			}
		}

		.form-field-type-date-range {
			& .date-range-container {
				position:absolute !important;
				top:0 !important;
				left: 0 !important;
			}
			& .flatpk-input.form-control.input {
				border:0 !important;
				margin:1px !important;
			}

			& .select2-selection__indicator {
				display:flex !important;
				justify-content: center !important;
				align-items: center !important;
				margin-left:.5rem;
				margin-right:.5rem;
			}
			& .date-range-custom-select {
				width: 100%;
				border:0 !important;
				background-image: none !important;
			}

			& .select2-selection__indicators {
				display: none;
			}
		}


		.form-label {
			font-family: $input-label-font-family;
			color:$form-label-color;
		}
		.form-label:after {
			background-color: transparent !important;
		}

		& .p-fieldset {
			width: 100%;
			margin-top: .8rem;
			margin-bottom: 1.8rem;
			position: relative;
			background: transparent !important;
			border:0 !important;

			&.no-padding {
				margin-top: 1rem;
				margin-bottom: 0;
				& .p-fieldset-content {
					margin-top:1rem;
					margin-bottom:0rem;
					padding-left:.75rem;
					padding-top:0;
					padding-bottom:0;
				}
				& .p-fieldset-legend {
					//margin-left: 0rem;
					//width: calc( 100% - 1rem ) !important;
				}
			}

			//padding-right: 1rem !important;
			& .p-fieldset-content {
				margin-top:.5rem;
			}

			&.p-fieldset-toggleable .p-fieldset-legend a {
				padding: 0;
			}

			&.p-fieldset-toggleable .p-fieldset-legend a {
				padding: 0;
			}

			& .p-fieldset-legend {
				margin-top: -1rem;
				margin-left: 1rem;
				margin-bottom: -1rem;
				padding: .5rem;
				font-size:.75rem;
				background: var(--black-2) !important;
				border:0 !important;
				width: calc( 100% - 2rem ) !important;
				font-family: $font-family-europa;
				text-transform: uppercase;
				//background: linear-gradient(0deg, rgba(250,251,254,1) 0%, rgba(225,226,230,1) 35%) !important;
				//background: red !important;
				color:white !important;
				& > span {
					justify-content: unset;
				}
			}

		}
		//& .form-input-title {
		//	color:white !important;
		//}
		& .input-color-label {
			color:$white !important;
			padding-top:.25rem !important;
			font-weight: bold;
		}

		& .input-file-label {
			padding-top: .25rem;
			color:$white !important;
			font-weight: bold;
		}

		& input[data-autocompleted],
		& input:-webkit-autofill,
		& input:-webkit-autofill:hover,
		& input:-webkit-autofill:focus,
		& input:-webkit-autofill:active {
			background: map-get($theme, input-background) !important;
			background-color: map-get($theme, input-background) !important;
			box-shadow: 0 0 0px 1000px map-get($theme, input-background) inset !important;
			border:unset !important;
			border:solid 1px map-get($theme, input-border-color) !important;
			-webkit-box-shadow: 0 0 0px 1000px map-get($theme, input-background) inset !important;
			color: $black !important;
			-webkit-text-fill-color: $black !important;
			transition: background-color 5000s ease-in-out 0s;
			font-family: var(--input-font-family) !important;

		}

		& input:-webkit-autofill::first-line {
			color: $black !important;
		}

	}


}

.form-field-tooltip {
	.tooltip {
		z-index: 999999;
		&.show {
			opacity: 1;
		}
		.tooltip-inner {
			background-color: $black-2;
			color: $white;
			padding: 1rem 1rem;
		}
		.tooltip-arrow {

		}
		.tooltip-arrow::before {
			border-top-color: $black-2 ;
			//margin-left: 10px;
		}
	}

}

@media (prefers-reduced-motion: reduce) {
	.form-floating > label {
		transition: transform .2s ease-in-out;
	}
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
	font-weight: bold;
}

//.form-field-type-form-array .form-input-row .form-field-col .form-field-group   {
//	margin-bottom:0rem !important;
//}

	.text-shadow-white {
		text-shadow: #fff 1px 0 1px;
	}
	::-webkit-file-upload-button {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		cursor:pointer;
	}

