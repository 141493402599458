$mobile-width:1279px;
$white:#F4F4F9;
$black:#313131;
$black-2 : #464646;
$slate:#464646;
$blue:#2E4770;
$blue-3:#20324E;
$blue-4:#6D7E9B;
$purple:#656B8F;
$purple-2:#B1B8DB;
$purple-3:#737AA3;
$gray:#B9BBBA;

$box-shadow-white : 0 0 0 0.25rem rgba($white, .20);
$box-shadow-gray : 0 0 0 0.25rem rgba($black, .20);

//$page-content-padding : calc( (100vw - 1440px) / 2 );
$page-content-padding : 0;

$font-tt : "Titillium Web", Roboto, sans-serif;
$font-roboto : Roboto, sans-serif;


$font-size-16: 1rem;
$font-size-18: 1.15rem;
$font-size-20: 1.5rem;
$font-size-24: 1.6rem;
$font-size-28: 2rem;
$font-size-32: 2.5rem;
$font-size-60: 4.1rem;
$font-size-96: 6rem;
$font-size-128: 7.5rem;

$lh-16: 1rem;
$lh-18: 1.3rem;
$lh-20: 1.2rem;
$lh-24: 1.7rem;
$lh-28: 2.2rem;
$lh-32: 2.2rem;
$lh-60: 4.4rem;
$lh-96: 7.3rem;
$lh-128: 8.3rem;

