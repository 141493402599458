.form-control-custom {
	font-size: 2.133vw;
	border: 1px solid #43597e;
}

.row-custom {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Two equal columns */
	grid-template-rows: repeat(3, auto) auto; /* Three rows + one row for the last item */

	justify-content: center;
}

.row-custom > :nth-child(6) {
	grid-column: 1 / -1; /* Span the last item across both columns */
}
