.text-16 {
	font-size: $font-size-16;
	line-height: $lh-16;
	@media (max-width: $mobile-width) {
		font-size: $font-size-16*1.5;
		line-height: $lh-16*1.5;
	}
}

.text-18 {
	font-size: $font-size-18;
	line-height: $lh-18;
	@media (max-width: $mobile-width) {
		font-size: $font-size-18*1.75;
		line-height: $lh-18*1.75;
	}
}

.text-20 {
	font-size: $font-size-20;
	line-height: $lh-20;
	@media (max-width: $mobile-width) {
		font-size: $font-size-20*1.25;
		line-height: $lh-20*1.25;
	}
}

.text-24 {
	font-size: $font-size-24;
	line-height: $lh-24;
	@media (max-width: $mobile-width) {
		font-size: $font-size-24*1.5;
		line-height: $lh-24*1.5;
	}
}

.text-28 {
	font-size: $font-size-28;
	line-height: $lh-28;
	@media (max-width: $mobile-width) {
		font-size: $font-size-28*1.25;
		line-height: $lh-28*1.25;
	}
}

.text-32 {
	font-size: $font-size-32;
	line-height: $lh-32;
	@media (max-width: $mobile-width) {
		font-size: $font-size-32*1.5;
		line-height: $lh-32*1.5;
	}
}

.text-60 {
	font-size: $font-size-60;
	line-height: $lh-60;
}

.text-96 {
	font-size: $font-size-96;
	line-height: $lh-96;
	@media (max-width: $mobile-width) {
		font-size: $font-size-96*.75;
		line-height: $lh-96*.75;
	}
}

.text-128 {
	font-size: $font-size-128;
	line-height: $lh-128;
	@media (max-width: $mobile-width) {
		font-size: $font-size-96*.75;
		line-height: $lh-96*.75;
	}
}

.text-title {
	font-size: 7.3rem;
	line-height: 8rem;
}
