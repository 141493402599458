@import "../../../../assets/scss/variables";

.service-success-cases {

	color:$black;
	padding-top: 2rem;

	& .pre-title {
		color:$blue;
		font-size: $font-size-28;
		line-height: $lh-28;
		font-weight: 600;
		text-align: center;
	}
	& .title {
		font-weight: 700;
		text-align: center;
	}

	& .icon-container {
		color: $blue;
	}

	& .image-container {
		margin-top: 3rem;
		background: #D9D9D9;
		z-index: 2;
		width: 100%;
		height: 28rem;
		& .image {
			border-radius: .5rem;
			box-shadow: 5px 5px 10px 0px #0000001A;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}

	& .solution-container {
		width: 100%;
		padding-top: 2rem;
		& .solution-name {
			font-size: $font-size-32;
			line-height: $lh-32;
			font-weight: 600;
			padding: 1rem;
			padding-left: 0 !important;
		}
		& .solution-description {
			text-align: right;
			font-size: $font-size-28;
			line-height: $lh-28;
			font-weight: 400;
		}
	}

	& .percentage {
		text-align: left;
		font-size: $font-size-60;
		line-height: $lh-60;
		font-weight: 600;
	}
	& .percent-explanation {
		text-align: right;
		font-size: $font-size-18;
		line-height: $lh-18;
		font-weight: 400;
		padding-right: 2rem;
		padding-top: 1rem;
		border-right: solid .2rem $black;
	}
	& .challenge-box {
		margin-top: 2rem;
		padding-right: 2rem;
		border-right: solid .2rem $black;
		gap: 1.5rem;
	}

	& .challenge-icon {
		padding-top: .5rem;
	}

	& .challenge-result {
		//font-size: $font-size-28;
		//line-height: $lh-28;
		//font-weight: 600;
		//padding: 1rem;
	}

	& .challenge-container {
		width: 100%;
		& .solution-description {
			text-align: left;
			font-size: $font-size-28;
			line-height: $lh-28;
			font-weight: 400;
		}
	}

}
