BODY {
	color:$black;
	background-color: $white;
	//zoom: 200%;
	font-family: "Roboto", sans-serif;
}

//* {
//	scroll-behavior: smooth !important;
//}

.page-base {
	margin: 0rem;
	height:100%;
	@media (max-width: $mobile-width) {
		overflow: hidden;
	}
}



.font-tt {
	font-family: $font-tt;
}


.page-content {
	width: 1440px;
	height:100%;
	@media (max-width: $mobile-width) {
		//border:solid 1px red !important;
		width: 100%;
	}
}

.padding-left-content {
	padding-left:$page-content-padding;
}

.margin-left-content {
	margin-left:$page-content-padding;
}

.block-content {
	//overflow: hidden;
	//width: 1440px;
	width: 1320px;
	@media (max-width: $mobile-width) {
		//border:solid 1px red !important;
		width: 100%;
		padding: 1rem 2rem !important;
	}
}

.page-content-left {
	padding-left: $page-content-padding;
	@media (max-width: $mobile-width) {
		padding-left: 0;
	}
}

.debug-on .debug{outline:solid 2px red !important;}
.debug-on .debug1{outline:solid 2px deeppink !important;}
.debug-on .debug2{outline:solid 2px green !important;}
.debug-on .debug3{outline:solid 2px yellow !important;}
.debug-on .debug4{outline:solid 2px #00FEFF !important;}
.debug-on .debug5{outline:solid 2px blue !important;}
.debug-on .debug6{outline:solid 2px #cbe4fb !important;}

.boxed {
	top:0;
	left:0;
	right:0;
	bottom:0;
}

.boxed-x {
	top:0;
	left:0;
	right:0;
}

.right-0 {
	right:0;
}

.no-pointer-event {
	pointer-events: none;
}

.grayscale {
	filter:grayscale(1);
}

A.link {
	color:$white;
	&:hover {
		text-decoration: underline;
	}
}

