@import "../../../../assets/scss/variables";

.footer {
	margin-top: 4rem;
	padding-top: 3rem;
	color: $white;
	background: linear-gradient(180deg, #313131 0%, #20324E 224.02%);

	& .menu-container {
		gap: 4rem;
		& .menu-box {


			& .title {
				font-weight: 700;
				padding-bottom: 1rem;
			}

			& .item {
				font-weight: 400;
				padding: .25rem 0rem;
				@media (max-width: $mobile-width) {
					padding: .5rem 0rem;
				}
				cursor:pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}

	}

	& .dompa-container {
		padding-top: 3rem;
		gap: 2rem;

		& .logo-div {
		}

		& .logo-container {

		}
		& .slogan {
			font-weight: 400;
			padding-top: 1rem;
			@media (max-width: $mobile-width) {
				padding-top: 0rem;
				padding-bottom: 1rem;
			}
		}
		& .actions {

		}
	}

	& .line-box {
		& .line {
			height:.05rem;
			background-color: $white;
		}
	}

	//& .background-image {
	//	background-repeat: no-repeat;
	//	background-size: cover;
	//	background-position-x: center;
	//	background-position-y: bottom;
	//}

}
