@import "../../../../assets/scss/variables";

.products {

	color:$black;
	//box-shadow: 5px 5px 10px 0px #0000001A;

	& .main-title {
		font-family: $font-tt;
		font-size: $font-size-96;
		font-weight: 700;
	}

	& .main-description {
		padding-top: 1rem;
		max-width: 50rem;
	}

	& .counter-line {
		width: .15rem;
		background: $black;
		margin-left: 6rem;
		margin-right: 2rem;
	}

	& .product-title {
		font-family: $font-tt;
		font-weight: 600;
		padding-right: 2rem;
	}
	& .product-description {
		//margin-right: 8rem;
		padding: 2rem 12rem 2rem 0rem;
		@media (max-width: $mobile-width) {
			padding-left: 0rem;
			padding-right: 0rem;
		}
	}

	& .image-container {
		min-width: 20rem;
		height: 30rem;
	}

	& .counter-container {
		padding-top: 3rem;
	}

	& .product-container {
		padding-top: 2rem;
	}

	& .image-box {
		//border: solid 1px yellow;
		//box-shadow: 5px 5px 10px 0px #0000001A;
		//border-radius: 10px;

		width:100%;
		height:100%;
	}

	& .image {
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: -140px;
		//background-position-y: center;
	}

	& .image {
		background-repeat: no-repeat;
		background-size: contain;
		background-position-x: right;
		background-position-y: center;
	}

	& .info-container {
		padding-left: 8rem;
		padding-right: 6rem;
		@media (max-width: $mobile-width) {
			padding-left: 0rem;
			padding-right: 0rem;
			padding-top: 2rem;
		}

	}

	& .menu-container {
		min-width: 14rem;
	}

	& .menu {
		white-space: nowrap;
		overflow-x: auto;
		& .item:not(:first-child) {
			padding-top: .75rem;
			@media (max-width: $mobile-width) {
				padding: 1rem 1rem;
			}
		}

		& .item {
			@media (max-width: $mobile-width) {
				padding: 1rem 1rem;
			}

			padding-bottom: .75rem;
			cursor: pointer;
			font-weight: 400;
			//font-size: $font-size-18;
			border-bottom: solid .15rem $black;
			text-align: right;
			color: $black;
			&.active {
				font-size: $font-size-28;
				font-weight: 600;
			}
		}
	}

}
