@import "../../../../assets/scss/variables";

.service-content {
	margin-top: -8rem;
	@media (max-width: $mobile-width) {
		margin-top: -4rem;
	}
}

.service-description {

	color:$black;

	& .icon {
		font-weight: 600;
	}
	& .title {
		font-weight: 700;
	}
	& .description {
		//margin-right: 8rem;
		padding: 2rem 12rem 2rem 0rem;
		@media (max-width: $mobile-width) {
			padding: 2rem 2rem 2rem 0rem;
		}
	}

	& .icon-container {
		color: $blue;
	}

	& .image-container {
		background: #D9D9D9;
		z-index: 2;
		max-width: 25rem;
		min-width: 25rem;
		& .image {
			border-radius: .5rem;
			box-shadow: 5px 5px 10px 0px #0000001A;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	}

}
